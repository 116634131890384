import { Component } from "react";
import { GlobalNavbar } from "../global-navbar/global-navbar.component";
import { WhatsappButton } from "../whatsapp-button/whatsapp-button.component";
import { Button, Header } from "./base-navbar.style-component";
import { Sidebar } from "./sidebar.component";
import { t } from "i18next";
import { TopBar } from "../top-bar/top-bar.component";

export class BaseNavbar extends Component {
  static isHome = true;
  static isInCheckout = location.pathname.indexOf("cotizar") > -1;

  public scrolled: boolean;
  public phone: string = "";

  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 912,
    };
  }

  scrollToSpvQuote = () => {
    const spvQuoteElement = document.getElementById("spv-quote-latest-home");

    if (spvQuoteElement) {
      spvQuoteElement.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = "/";
    }
  };

  componentDidMount() {
    window["env"].parameters.then((parameters) => {
      const whatsapp =
        (parameters.addresses && parameters.addresses.whatsapp) ||
        parameters.phone;

      this.phone = whatsapp.replace(/ /g, "").replace("+", "");
      this.forceUpdate();
    });

    window.addEventListener("scroll", () => {
      if (
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) > 900
      ) {
        this.scrolled = true;
      } else if (
        ((this.scrolled && window.pageYOffset) ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) < 900
      ) {
        this.scrolled = false;
      }
      this.forceUpdate();
    });

    window.addEventListener("resize", this.updateViewportState);
  }

  updateViewportState = () => {
    this.setState({
      isMobile: window.innerWidth <= 912,
    });
  };

  render() {
    const isMobile = window.innerWidth <= 912;
    const logo =
      "https://res.cloudinary.com/pervolare-org/image/upload/v1649109843/spv/sites/images/home/SPV-marca_m5ffnx.webp";
    const logoMobile =
      "https://res.cloudinary.com/pervolare-org/image/upload/v1701117400/spv/sites/images/header/logo-mobile_fow8zc.png";
    const logoWhenScrolled =
      "https://res.cloudinary.com/pervolare-org/image/upload/v1715716042/spv/sites/images/header/logo-white-mobile_v8dykp.webp";

    let selectedLogo = isMobile ? logoWhenScrolled : logo;
    if (isMobile) {
      selectedLogo = logoWhenScrolled;
    }

    const topLevelDomain = localStorage.getItem("spv_topLevelDomain");
    const getClassName = () => {
      let baseClass = "d-flex middle-xs line-item-base-navbar";
      if (topLevelDomain === "n&o") {
        return this.scrolled
          ? `${baseClass} w-header-scroll-ec f-10-scl-ec`
          : `${baseClass} w-header-ec f-12-ec`;
      } else {
        return this.scrolled
          ? `${baseClass} w-header-scroll`
          : `${baseClass} w-header`;
      }
    };

    return (
      <>
        <TopBar />

        <Header
          linksColor={BaseNavbar.isHome ? "f5f5f5" : "0c1e4a"}
          className={this.scrolled ? "header-fixed" : "header-relative borderb"}
        >
          <div
            className={
              this.scrolled ? "container-fluid p-mobile" : "container-fluid"
            }
            style={{ height: "100%" }}
          >
            <div className="row bg-mx-0 h-navbar" style={{ height: "100%" }}>
              <div className="col-xs-12 col-lg-12 col-md-12 px-4 d-flex middle-xs between-xs">
                <a title="home page" href="/" style={{ width: "auto" }}>
                  <img
                    className={
                      BaseNavbar.isHome ? "d-block mr-2 logo-home" : "d-none"
                    }
                    src={this.scrolled ? selectedLogo : logoMobile}
                    alt="Seguro de viaje - Asistencia Visa Schengen"
                    loading="eager"
                    width={this.scrolled && isMobile ? "55" : "190"}
                    height={this.scrolled && isMobile ? "60" : "86"}
                  />

                  <img
                    className={
                      BaseNavbar.isHome ? "d-none" : "d-block mr-2 logo-page"
                    }
                    src={logoMobile}
                    alt="Seguro de viaje - Asistencia Visa Schengen"
                    width="195"
                    height="90"
                  />
                </a>

                {!BaseNavbar.isInCheckout ? (
                  <div className={getClassName()} style={{ zIndex: 1000 }}>
                    <GlobalNavbar phone={this.phone} />

                    {this.scrolled ? (
                      <Button
                        onClick={this.scrollToSpvQuote}
                        className="ml-lg-5 d-lg-none center-xs middle-xs Gotham-bold"
                      >
                        {t("Get a free quote")}
                      </Button>
                    ) : (
                      <div className="d-lg-none div-button-wpp">
                        <WhatsappButton phone={this.phone} />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <button
                  className={
                    this.scrolled
                      ? "openbtn btn text-light f-30 ml-3 d-lg-none btn-mobile-scroll"
                      : "openbtn btn text-light f-30 ml-3 d-lg-none btn-mobile"
                  }
                  onClick={this.openNav}
                >
                  &#9776;
                </button>
              </div>
            </div>
          </div>

          <Sidebar />
        </Header>
      </>
    );
  }

  openNav(): void {
    document.getElementById("sidePanel").style.width = "100vw";
  }
}
