import React from "react";
import { CountdownContainer } from "./countdown.style-component";
import { t } from "i18next";

export type CountdownProps = {
  endDate: string;
};

export default class Countdown extends React.Component<CountdownProps> {
  private days = "0";
  private hours = "0";
  private minutes = "0";
  private seconds = "0";
  private expired = false;

  constructor(props: CountdownProps) {
    super(props);
  }

  componentDidMount() {
    const countDownDate = new Date(this.props.endDate).getTime();

    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Poner ceros a la izquierda
      this.days = days < 10 ? `0${days}` : `${days}`;
      this.hours = hours < 10 ? `0${hours}` : `${hours}`;
      this.minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      this.seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      if (distance < 0) {
        clearInterval(countdown);
        this.expired = true;
      }

      this.forceUpdate();
    }, 1000);
  }

  render() {
    return (
      <CountdownContainer className="d-flex ml-lg-auto h-dynamic">
        {this.expired && <h2>{t("countdown.Promotion expired")}</h2>}
        {!this.expired && (
          <div>
            <p className="countdown Gotham">
              <span className="time">{this.days}</span>&nbsp;<span>:</span>
              &nbsp;<span className="time">{this.hours}</span>&nbsp;
              <span>:</span>&nbsp;<span className="time">{this.minutes}</span>
              &nbsp;<span>:</span>&nbsp;
              <span className="time">{this.seconds}</span>
            </p>
            <small className="d-flex between-xs Gotham">
              <span>{t("countdown.Day")}</span>&nbsp;
              <span>{t("countdown.Hours")}</span>&nbsp;
              <span>{t("countdown.Minutes")}</span>&nbsp;
              <span>{t("countdown.Seconds")}</span>
            </small>
          </div>
        )}
      </CountdownContainer>
    );
  }
}
