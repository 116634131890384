import styled from "styled-components";

export const CountdownContainer = styled.div`
  .countdown {
    color: #ffffff;
    margin: 0;
    display: block;
    font-size: 1rem;
    text-align: left;
    font-weight: 900;
    line-height: 1.2rem;
    letter-spacing: 0.05em;
  }
  small {
    opacity: 0.7;
    color: #ffffff;
    min-width: 11rem;
    font-size: 0.688rem;
    text-align: center;
    font-weight: 300;
    margin-left: 0rem;
    line-height: 0.825rem;
  }
  .time {
    width: 1.688rem;
    display: inline-flex;
  }
  &.h-dynamic {
    /* height: 4.063rem; */
    align-items: center;
    margin-bottom: 8px;
  }
  @media (min-width: 992px) {
    .countdown {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    small {
      min-width: 14rem;
      font-size: 0.688rem;
      min-width: 15.625rem;
      line-height: 0.525rem;
      margin-left: 0.625rem;
    }
    .time {
      width: 2.688rem;
    }
    &.h-dynamic {
      height: 4.063rem;
      align-items: center;
      margin-bottom: 0px;
    }
  }
`;
