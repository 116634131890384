import styled from "styled-components";

export const Ul = styled.ul`
  overflow: hidden;
  flex-wrap: wrap;

  li {
    float: left;
    overflow: hidden;
  }
  li:hover .dropdown-content {
    display: block;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .toggle-not-scrolled::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-content {
    z-index: 1;
    display: none;
    /* padding: 10px 0px; */
    position: absolute;
    height: auto;
    min-width: 160px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
    border-radius: 0 30px 0 0;
  }
  .dropdown-content a {
    float: none;
    padding: 12px 0px;
    display: block;
    text-align: left;
    text-decoration: none;
  }
  .btn-report-emergency {
    color: #2855a0;
    border: 2px solid #2855a0;
    border-radius: 50px;
  }
  .btn-report-emergency-white {
    border: 2px solid white;
    border-radius: 50px;
  }
  .dropdown-content a {
    color: #0c1e4a;
    border-bottom: 1px solid #80808054;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dropdown-content a:last-child {
    border-bottom: none;
  }
  .dropdown-content a:hover {
    background-color: #0c1e4a;
    color: white;
  }
  .dropdown-content a:first-child:hover {
    border-radius: 0 30px 0 0;
  }
  .aling-center-w {
    align-items: center;
  }
  @media (min-width: 992px) {
    .new-home-text {
      color: #0c1e4a !important;
      & a {
        color: #0c1e4a !important;
      }
    }
  }
`;
