import { Component } from "react";

export class WhatsappButton extends Component<{ phone: string }> {
  constructor(props) {
    super(props);
  }

  render() {
    const whatsappImage =
      "https://res.cloudinary.com/pervolare-org/image/upload/v1693514364/spv/sites/images/header/whatsapp_vdczcv.png";

    return (
      <a
        href={
          "https://api.whatsapp.com/send?phone=" +
          this.props.phone +
          "&text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20seguro%20de%20viaje"
        }
        className="btn-whatsapp btn-responsive"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          width="34"
          height="34"
          className="btn-whatsapp"
          src={whatsappImage}
          alt="Whatsapp"
        />
      </a>
    );
  }
}
