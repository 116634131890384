import styled from "styled-components";

export const Div = styled.div`
  height: auto;
  background-color: #13141c;

  .text-copy {
    color: #ffffff;
    margin: 0;
    font-size: 0.75rem;
    text-align: left;
    font-weight: 500;
    line-height: 0.9rem;
    letter-spacing: 0.02em;
    padding: 10px 0px;
  }
  .row {
    height: 100%;
  }
  &.close {
    height: 0;
  }
  .aling-center {
    align-self: center;
  }
  @media (min-width: 992px) {
    height: auto;

    .text-copy {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: 10px 0px;
    }
  }
`;
