import { Component } from "react";
import { NAV_LINKS } from "./navbar-data";
import { Ul } from "./global-navbar.style-component";
import { WhatsappButton } from "../whatsapp-button/whatsapp-button.component";
import { t } from "i18next";
import { Button } from "../base-navbar/base-navbar.style-component";

declare function seoInfo(action: string): any;

export class GlobalNavbar extends Component<{ phone: string }> {
  public items = NAV_LINKS;
  public allowedDomains = ["co", "mx", "pe", "cl", "ec", "om"];
  public allowedDomainsForPQR = ["pe"];
  public isDomainAllowed = this.allowedDomains.includes(
    localStorage.getItem("spv_topLevelDomain")
  );
  public isDomainAllowedForPQR = this.allowedDomainsForPQR.includes(
    localStorage.getItem("spv_topLevelDomain")
  );
  public scrolled: boolean;

  scrollToSpvQuote = () => {
    const spvQuoteElement = document.getElementById("spv-quote-latest-home");

    if (spvQuoteElement) {
      spvQuoteElement.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = "/";
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) > 900
      ) {
        this.scrolled = true;
      } else if (
        ((this.scrolled && window.pageYOffset) ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) < 900
      ) {
        this.scrolled = false;
      }
      this.forceUpdate();
    });

    window.addEventListener("resize", this.updateViewportState);
  }

  updateViewportState = () => {
    this.setState({
      isMobile: window.innerWidth <= 912,
    });
  };

  eventSEO(action: string) {
    if (typeof seoInfo !== "function") return;

    seoInfo(action.replace(" ", "_"));
  }

  render() {
    return (
      <Ul className="d-none d-lg-flex center-sm">
        <li className="mx-3 d-flex aling-center-w">
          <WhatsappButton phone={this.props.phone} />
        </li>
        {this.items.map((item, index) => (
          <li key={index.toString()}>
            <button
              className={
                this.scrolled
                  ? "dropdown-toggle btn Gotham-bold bnt-quote-model"
                  : "btn Gotham-bold toggle-not-scrolled"
              }
              onMouseLeave={() => this.eventSEO(item.displayName)}
            >
              {t(item.displayName)}
            </button>

            <div className="dropdown-content">
              {item.children.map((subItem, i) => (
                <a
                  key={"navlink-" + i.toString()}
                  href={subItem.link}
                  className="f-16 Gotham"
                  title={t(subItem.displayName)}
                >
                  {t(subItem.displayName)}
                </a>
              ))}

              {this.isDomainAllowedForPQR && index == 4 && (
                <a
                  key={"navlink-" + item.children.length}
                  href="/reclamos"
                  className="f-16 text-dark Gotham"
                  title="Reclamos"
                >
                  {t("Claims")}
                </a>
              )}
            </div>
          </li>
        ))}
        <li className="mx-3 d-flex aling-center-w">
          {this.scrolled ? (
            <div>
              <Button
                onClick={this.scrollToSpvQuote}
                className="center-xs middle-xs Gotham-bold mr-4"
              >
                {t("Get a free quote")}
              </Button>
              <a
                className="btn Gotham-bold btn-report-emergency-white text-white"
                href="https://api.whatsapp.com/send?phone=12018016266&text=Hola,%20necesito%20solicitar%20una%20asistencia%20o%20reportar%20un%20siniestro."
                target="_blank"
              >
                {t("Report emergency")}
              </a>
            </div>
          ) : (
            <a
              className="btn Gotham-bold btn-report-emergency"
              href="https://api.whatsapp.com/send?phone=12018016266&text=Hola,%20necesito%20solicitar%20una%20asistencia%20o%20reportar%20un%20siniestro."
              target="_blank"
            >
              {t("Report emergency")}
            </a>
          )}
        </li>
      </Ul>
    );
  }
}
