import React, { useRef } from "react";
import { AccordionContainer } from "./accordion.style-component";

export type AccordionProps = {
  id: string;
  isOpen: boolean;
  body: JSX.Element;
  header: JSX.Element;
  onToggle: (id: string) => void;
};

const Accordion: React.FC<AccordionProps> = ({
  id,
  isOpen,
  header,
  body,
  onToggle,
}) => {
  const panelRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    onToggle(id);
  };

  return (
    <AccordionContainer>
      <button
        className={`accordion ${isOpen ? "active" : ""}`}
        onClick={handleClick}
      >
        {header}
      </button>
      <div
        className={`panel ${isOpen ? "open" : ""}`}
        style={{
          maxHeight:
            isOpen && panelRef.current
              ? panelRef.current.scrollHeight + "px"
              : 0,
        }}
      >
        <div ref={panelRef}>{body}</div>
      </div>
    </AccordionContainer>
  );
};

export default Accordion;
