import styled from "styled-components";

export const SidePanel = styled.div`
  top: 0;
  width: 0;
  right: 0;
  z-index: 2000;
  position: fixed;
  overflow-x: hidden;
  /* min-height: 100vh; */
  transition: 0.5s;
  background-color: rgba(19, 20, 28, 0.81);

  .container-fluid {
    width: 80%;
    height: 100%;
    padding-top: 50px;
    background-color: #ffffff;
  }

  .closebtn {
    top: 29px;
    right: 16px;
    position: absolute;
    text-decoration: none;
    border-radius: 5px;
    background: #2855a0;
  }

  span {
    font-family: "Gotham";
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #0c1e4a;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  li a {
    text-decoration: none;
  }

  .content-links {
    height: 91vh;
    overflow-y: scroll;
  }

  .accordion {
    height: 111px;
    margin-bottom: -1px;

    &.active .arrow img {
      transform: rotate(180deg);
    }
  }

  .accordion {
    height: auto;
    background-color: transparent !important;
    padding: 18px 10px;
    .arrow img {
      transform: rotate(0deg);
    }
    &.active {
      color: #024dbc;
    }
  }
  .accordion.active .change-styles {
    border-bottom: none !important;
    padding-bottom: 0px;
  }
  .panel {
    padding: 0px 0px !important;
  }
  /* .panel.open {
    margin-top: -24px!important;
  } */
  .content-ul {
    padding: 10px 0px;
  }
  .content-ul li {
    padding-bottom: 15px !important;
    border-bottom: 1px solid #80808054;
  }
  .content-ul a {
    padding-left: 18px;
    color: #0c1e4a !important;
  }

  .content-ul li:hover {
    background: #0c1e4a;
    & a {
      color: white !important;
    }
  }
  .content-ul a:hover {
    background: #0c1e4a;
    color: white !important;
  }
  .bg-header {
    position: relative;
    margin-top: 0px;
    margin-bottom: 60px;
    float: right;
    padding-right: 9px;
  }
  .arrow {
    float: right;
  }
  .btn-report-emergency {
    color: #2855a0;
    border: 1px solid #2855a0;
    border-radius: 50px;
  }
`;
